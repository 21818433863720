<template>
  <div class="chat-record-container">

    <a-spin :spinning="isSecondListLoading">
      <div ref="secondListRef"
           class="second-list">
        <p class="mb-16 text-16 font-medium">群聊</p>

        <a-input-search v-model:value="queryForGroup.groupName"
                        class="mb-8"
                        style="width: 240px"
                        placeholder="请输入群聊名称"
                        @change="searchByGroupName" />

        <ul v-if="reply.list.length"
            ref="secondListRef"
            @scroll="loadMoreSecond">
          <li v-for="data in reply.list"
              :key="data.id"
              class="avatar-item cursor-pointer text-12 group"
              :class="{active: data.active}"
              @click="selectReply(data)">
            <svg-icon class="text-32"
                      type="iconqunliao" />
            <div class="flex-1 mr-24 ml-8">
              <div class="avatar-item__title">
                <span class="line-clamp-1"
                      style="max-width: 100px">{{data.name}}</span>
                <span class="text-color-999">{{computeLastReplyTime(data.lastMsgTime )}}</span>
              </div>
              <span class="text-color-999 line-clamp-1"
                    style="max-width: 200px">{{data.simpleContent}}</span>
            </div>
          </li>

          <li v-if="reply.loading"
              class="mt8 text-center">
            <a-spin />
          </li>
        </ul>
        <a-empty v-else
                 :image="simpleImage"
                 description="暂无存档数据" />
      </div>
    </a-spin>

    <record-list :reply="currentActive.group"
                 type="group"
                 :sender-id="senderId"
                 :loading="isSecondListLoading">
    </record-list>

  </div>
</template>

<script>
import _ from "lodash";
import { defineComponent, ref, onMounted, reactive } from "vue";
import { Empty } from "ant-design-vue";

import { computeLastReplyTime } from "@/global";
import RecordList from "./RecordList";
import SvgIcon from "@/components/SvgIcon";

import chatRecordApi from "@/service/api/chatRecord";
import { apiStore } from "@/shared/service/api-client";

export default defineComponent({
  name: "ChatRecordByGroup",

  components: {
    AEmpty: Empty,
    RecordList,
    SvgIcon,
  },

  setup() {
    const currentActive = reactive({
      group: {},
    });

    const isSecondListLoading = ref(false);

    const queryForGroup = reactive({
      groupName: "",
      page: 0,
    });

    const reply = reactive({
      list: [],
      loading: false,
      finished: false,
    });

    const initReply = () => {
      _.assign(reply, {
        list: [],
        loading: false,
        finished: false,
      });

      queryForGroup.page = 0;
    };

    function selectReply(data) {
      currentActive.group.active = false;
      currentActive.group = data;
      data.active = true;
    }

    const searchByGroupName = _.debounce(() => {
      initReply();
      getReplyList();
    }, 500);

    const secondListRef = ref();

    const loadMoreSecond = _.debounce(() => {
      if (!secondListRef.value) {
        return;
      }

      const { scrollHeight, scrollTop, clientHeight } = secondListRef.value;

      if (scrollHeight - clientHeight - scrollTop < 80 && !reply.finished) {
        loadReplyList();
      }
    }, 500);

    async function loadReplyList() {
      reply.loading = true;
      const res = await chatRecordApi.getGroupList(queryForGroup);
      reply.list = _.concat(reply.list, res.data);
      queryForGroup.page++;
      reply.loading = false;

      reply.finished = reply.list.length >= res.total;
    }

    async function getReplyList() {
      isSecondListLoading.value = true;
      await loadReplyList();
      isSecondListLoading.value = false;

      if (_.isEmpty(reply.list)) {
        currentActive.group = {};
        return;
      }

      selectReply(reply.list[0]);
    }

    onMounted(() => {
      getReplyList();
    });

    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      currentActive,
      senderId: apiStore.user.id,

      reply,
      isSecondListLoading,
      queryForGroup,
      selectReply,
      searchByGroupName,

      secondListRef,
      loadMoreSecond,

      computeLastReplyTime,
    };
  },
});
</script>
<style lang='less' scoped>
:deep(.ant-tabs-bar) {
  margin-bottom: 16px;
}

:deep(.ant-tabs-tab) {
  color: @gray;
}

:deep(.ant-tabs-tab-active) {
  color: @color-primary;
}
</style>
